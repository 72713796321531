.page--text-page {
    @include padding-container;
    padding-top: 80px;
    padding-bottom: 80px;

    .text {
        // &__title,
        // &__body {
        //     @include for-tablet-portrait-up {
        //         max-width: 1000px;
        //     }
        // }

        &__title {
            @include heading1;
            margin-bottom: 16px;

        }
        &__body {
            @include body-text;
        }
    }
}
