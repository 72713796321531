.contact-poi {
    display: flex;
    flex-direction: column-reverse;

    @include for-tablet-portrait-up {
        flex-direction: row;
    }

    &__left,
    &__right {
        @include for-tablet-portrait-up {
            width: 50%;
        }
    }

    &__left {
        background-color: $color-blue;
        @include padding-container;
        padding-top: 60px;
        padding-bottom: 60px;

        @include for-tablet-portrait-up {
            padding-top: 104px;
            padding-bottom: 95px;
        }

        &__content {
            @include for-tablet-portrait-up {
                max-width: 355px;
            }

            @include freeform;
        }
    }

    &__right {
        #pois-map {
            width: 100%;
            height: 75vw;

            @include for-tablet-portrait-up {
                height: 100%;
            }
            display: block;

            .poi-infowindow {
                .mapboxgl-popup {
                    &-tip {
                        border: 10px solid transparent;
                        border-bottom: none;
                        border-right: none;
                        border-top-color: $color-orange3;
                    }

                    &-content {
                        background-color: $color-orange3;
                        color: black;
                        transform: translate(10px, -10px);
                        padding: 20px;
                        color: $color-blue;
                    }
                    &-close-button {
                    }
                }
            }
        }
    }

    // Typo

    &__title {
        color: $color-white;
        font-size: 28px;
        font-weight: $font-weight-bold;
        letter-spacing: -0.02em;
        line-height: 1.035;
        margin-bottom: 6px;
    }

    &__text {
        color: $color-white;
        font-size: 16px;
        font-weight: $font-weight-light;
        line-height: 1.25;
        margin-bottom: 25px;
    }
}
