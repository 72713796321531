// Buttons //
@mixin button-blue {
    @error "Mixin 'button-blue' needs to be added";
}

@mixin button-outline-blue {
    @include button(
        $backgroundColor: transparent,
        $color: $color-blue,
        $hoverBackgroundColor: $color-blue,
        $hoverColor: $color-white,
        $padding: 13px 23px,
        $fontSize: 13px,
        $fontStack: $font-base,
        $textTransform: uppercase
    );

    font-weight: $font-weight-regular;
    border: 1px solid $color-blue;
}

@mixin button-orange {
    @include button(
        $backgroundColor: $color-orange,
        $color: $color-white,
        $hoverBackgroundColor: $color-orange2,
        $hoverColor: $color-white,
        $padding: 13px 23px,
        $fontSize: 14px,
        $fontStack: $font-base,
        $textTransform: uppercase
    );

    font-weight: $font-weight-bold;
}

@mixin button-green {
    @include button(
        $backgroundColor: $color-tondelier-green,
        $color: $color-white,
        $hoverBackgroundColor: $color-tondelier-red,
        $hoverColor: $color-white,
        $padding: 13px 23px,
        $fontSize: 14px,
        $fontStack: $font-base,
        $textTransform: uppercase
    );

    font-weight: $font-weight-bold;
}

@mixin rounded-button-orange {
    @include button(
        $backgroundColor: $color-orange,
        $color: $color-white,
        $hoverBackgroundColor: $color-orange2,
        $hoverColor: $color-white,
        $padding: 13px 23px,
        $fontSize: 13px,
        $fontStack: $font-base,
        $textTransform: uppercase
    );

    border-radius: 200px;
    font-weight: $font-weight-bold;

    svg {
        margin-top: -13px;
        margin-bottom: -13px;
        margin-right: 10px;
        fill: $color-white;
    }

    span {
    }
}

// Titles and text //

@mixin heading1($color: $color-blue) {
    color: $color;
    font-size: 26px;
    font-weight: $font-weight-bold;
    letter-spacing: -0.02em;
    line-height: 1.23;
}

@mixin heading2($color: $color-blue) {
    color: $color;
    font-size: 20px;
    font-weight: $font-weight-bold;
    line-height: 1.15;
}

@mixin heading3($color: $color-blue) {
    color: $color;
    font-size: 15px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    line-height: 1.4;
}

@mixin intro($color: $color-black) {
    color: $color;
    font-size: 16px;
    font-weight: $font-weight-regular;
    line-height: 1.375;
}

@mixin text($color: $color-black) {
    color: $color;
    font-size: 15px;
    font-weight: $font-weight-light;
    line-height: 1.375;
}

@mixin body-text() {
    h2 {
        @include heading2;
        margin-bottom: 16px;
    }

    h3 {
        @include heading3;
        // margin-bottom: 16px;
    }

    p {
        @include text();

        strong {
            @include intro();
        }

        a {
            color: $color-blue;
            transition: color $anim-duration-link ease-in-out;
            text-decoration: none;

            &:hover {
                color: $color-black;
            }
        }

    }

    blockquote {
        @include text();
        color: $color-grey2;
        font-weight: 300;
        padding-left: 15px;
        border-left: 1px solid lighten($color-grey2, 15%);
    }

    > *:not(:last-child) {
        margin-bottom: 1rem;
    }
}


@mixin backLink() {
    color: $color-grey3;
    font-size: 14px;
    font-weight: $font-weight-regular;
    text-transform: lowercase;
    text-decoration: none;
    transition: color $anim-duration-link;

    &:hover {
        color: $color-grey2;
    }
}