.footer {
    .footer-top {
        background-color: $color-orange;
        padding-top: 83px;
        padding-bottom: 67px;
        @include padding-container;

        &__top {
            text-align: center;
            margin-bottom: 18px;

            @include for-tablet-portrait-up {
                text-align: left;
                margin-bottom: 0;
            }

            .logo {
                display: inline-block;
                margin-bottom: 19px;
            }

            .title {
                @include heading1;
                font-size: 24px;

                @include for-tablet-portrait-up {
                    font-size: 26px;
                }

                margin-bottom: 3px;

                a {
                    color: inherit;
                    text-decoration: none;
                    transition: color $anim-duration-link ease-in-out;

                    &:hover {
                        color: $color-white;
                    }
                }
            }

            .address {
                font-size: 15px;
                font-weight: $font-weight-regular;
                color: $color-white;
            }
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;

            @include for-tablet-portrait-up {
                flex-direction: row;
                align-items: flex-end;
                // margin-top: -35px;
            }

            .social-nav {
                margin-bottom: 18px;

                @include for-tablet-portrait-up {
                    margin-bottom: 0;
                }

                &__list {
                    display: flex;
                }

                &__item {
                    &:not(:first-child) {
                        margin-left: 20px;
                    }

                    a {
                        display: flex;
                        width: 38px;
                        height: 38px;
                        background-color: $color-white;
                        border-radius: 100%;
                        justify-content: center;
                        align-items: center;
                        transition: background-color $anim-duration-link ease-in-out;

                        &:hover {
                            background-color: $color-blue;

                            svg {
                                fill: $color-white;
                            }
                        }
                    }

                    svg {
                        transition: fill $anim-duration-link ease-in-out;
                        fill: $color-blue;
                    }
                }
            }

            .oryx {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                
                @include for-tablet-portrait-up {
                    // align-items: flex-end;
                    // flex-direction: row;
                    align-items: flex-end;
                }

                &__label {
                    font-weight: $font-weight-regular;
                    color: $color-white;
                    text-align: center;

                    @include for-tablet-portrait-up {
                        text-align: right;
                    }

                    a {
                        color: inherit;
                        text-decoration: none;
                        transition: color $anim-duration-link ease-in-out;

                        &:hover {
                            color: $color-blue;
                        }
                    }
                }

                &__logo {
                    display: inline-block;
                    margin-left: 0;
                    margin-top: 30px;
                    
                    @include for-tablet-portrait-up {
                        margin-left: 18px;
                        margin-top: 0;
                    }

                    svg {
                        display: block;
                        width: 200px;
                        height: 25px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    .footer-bottom {
        background-color: $color-orange4;
        color: $color-orange2;
        font-size: 12px;
        font-weight: $font-weight-regular;
        padding-top: 26px;
        padding-bottom: 66px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include padding-container;

        @include for-tablet-portrait-up {
            flex-direction: row;
            align-items: flex-start;
            padding-bottom: 26px;
        }

        a {
            color: inherit;
            text-decoration: none;
            transition: color $anim-duration-link ease-in-out;

            &:hover {
                color: $color-blue;
            }
        }

        .maister-logo {
            width: 15px; // width of svg
            transition: all $anim-duration;
            overflow: hidden;
            display: inline-block;
            vertical-align: middle;
            user-select: none;

            &:hover {
                width: 116px;
            }

            a {
                text-align: left;
                background: transparent;
                display: flex;
                align-items: center;
                text-decoration: none;
                color: inherit;

                > * {
                    flex-shrink: 0;
                }

                svg {
                    display: inline-block;
                    fill: currentColor;
                }

                span {
                    color: currentColor;
                    display: inline-block;
                    margin-left: 6px;
                    text-transform: lowercase;
                    transform: translateY(-1px);
                }
            }
        }

        > *:not(:last-child) {
            margin-bottom: 20px;

            @include for-tablet-portrait-up {
                margin-right: 26px;
                margin-bottom: 0;
            }
        }
    }
}
