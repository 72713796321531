@mixin building-label() {
    display: inline-flex;
    align-items: center;
    padding: 7.5px 12px;
    border: 1px solid currentColor;
    color: $color-grey3;
    margin-bottom: 12px;

    svg {
        fill: currentColor;
        // margin-right: 14px;
    }

    span {
        font-weight: $font-weight-bold;
        font-size: 13px;
        text-transform: uppercase;
        transform: translateY(1px);
    }
}