.page--storiesIndexKeizerpoort {
    .stories-overview {
        padding-top: 60px;
        padding-bottom: 97px;

        @include padding-container;

        @include for-tablet-portrait-up {
            padding-top: 67px;
        }

        &__title {
            color: $color-blue;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            line-height: 1.17;
            margin-bottom: 20px;
        }

        &__filters {
            display: grid;
            grid-template-rows: 1fr;
            grid-auto-flow: column;
            grid-gap: 20px;
            justify-content: center;
            margin-bottom: 20px;
            @include for-tablet-portrait-up {
                margin-bottom: 60px;
            }
            & > li {
                color: $color-blue;
                &.active {
                    &:not(:first-child) {
                        color: $color-orange;
                        a {
                            font-weight: bold;
                        }
                    }
                    a {
                        cursor: default;
                    }
                }
                a {
                    color: inherit;
                    text-decoration: none;
                    font-weight: 500;
                    transition: all 0.3s ease;
                }
                &:not(.active) {
                    a:hover {
                        color: $color-orange;
                    }
                }
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__item {
            width: 100%;

            @include for-tablet-landscape-up {
                width: calc(#{percentage(1/2)} - 16px);
            }

            @include for-desktop-up {
                width: calc(#{percentage(1/3)} - 16px);
            }
        }

        .story-card {
            text-decoration: none;

            &__image {
                position: relative;
                margin-bottom: 10px;

                img {
                    width: 100%;
                }
            }

            &__body {
                @include for-tablet-landscape-up {
                    // Give all direct childs a max width
                    > * {
                        max-width: 313px;
                    }
                }
            }

            &__date,
            &__title,
            &__preface {
                transition: color $anim-duration ease-in-out;
            }

            &__readmore {
                position: absolute;
                bottom: 0;
                left: 0;
                opacity: 0;

                color: $color-white;
                font-size: 14px;
                font-weight: $font-weight-bold;
                text-transform: uppercase;

                display: inline-block;
                background-color: $color-orange;
                padding: 13px 23px;
                user-select: none;
                outline: none;
                transition: opacity $anim-duration ease-in-out;
            }

            &__date {
                display: inline-block;
                color: $color-grey2;
                font-weight: $font-weight-light;
                font-size: 15px;
                line-height: 1.4;
                margin-bottom: 10px;
            }

            &__title {
                color: $color-blue;
                font-size: 18px;
                line-height: 1.16;
                margin-bottom: 11px;
            }

            &__preface {
                color: $color-blue;
                font-weight: $font-weight-light;
                font-size: 15px;
                line-height: 1.4;
            }

            &:hover {
                .story-card {
                    &__date,
                    &__title,
                    &__preface {
                        color: $color-orange;
                    }

                    &__readmore {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .stories-pagination {
        display: none; // hide for now

        @include padding-container;
        text-align: center;
        padding-bottom: 113px;

        &__more {
            @include button-outline-blue;
        }
    }
}
