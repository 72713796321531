.photo-swiper {
    &-wrapper {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;

        .swiper {
            &-container {
                height: 100%;
            }
            &-wrapper {
                height: 100%;
            }
            &-slide {
                background-color: $color-grey4;
                display: flex;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    font-family: "object-fit: cover;";
                }

                .video {
                    @include video-icon;
                }
            }

            &-nav {
                .swiper-button-prev,
                .swiper-button-next {
                    @include for-tablet-portrait-up {
                        position: absolute;
                        top: 50%;

                        width: 50px;
                        z-index: 1;
                    }
                }
                .swiper-button-prev {
                    @include swiper-button-prev;

                    @include for-tablet-portrait-up {
                        left: calc(22.5% - 30px);
                        transform: translateX(-50%);
                    }

                    @include for-desktop-up {
                        left: calc(22.5% - 91px);
                        transform: translateX(-50%);
                    }
                }

                .swiper-button-next {
                    @include swiper-button-next;

                    @include for-tablet-portrait-up {
                        right: calc(22.5% - 30px);
                        transform: translateX(50%);
                    }

                    @include for-desktop-up {
                        right: calc(22.5% - 91px);
                        transform: translateX(50%);
                    }
                }
            }
        }
    }

    &-middle {
        @include for-tablet-portrait-up {
            width: 55%;
        }
    }

    &-prev,
    &-next {
        display: none;
        visibility: hidden;

        @include for-tablet-portrait-up {
            display: block;
            visibility: visible;
            flex: 1;
            cursor: pointer;
        }
    }

    &-prev {
        @include for-tablet-portrait-up {
            margin-left: calc(-22.5% + 30px);
            margin-right: 30px;
        }

        @include for-desktop-up {
            margin-left: calc(-22.5% + 91px);
            margin-right: 91px;
        }
    }

    &-next {
        @include for-tablet-portrait-up {
            margin-right: calc(-22.5% + 30px);
            margin-left: 30px;
        }

        @include for-desktop-up {
            margin-right: calc(-22.5% + 91px);
            margin-left: 91px;
        }
    }
}
