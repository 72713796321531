.tondelier-cta {
    @include padding-container;
    border-top: 1px solid $color-grey4;
    background-color: $color-white;
    padding-top: 60px;;
    padding-bottom: 60px;
    text-align: center;

    @include for-tablet-portrait-up {
        padding-top: 108px;
        padding-bottom: 108px;
    }

    &__logo {
        margin-bottom: 23px;

        img {
            display: inline-block;
            width: 139px;
            height: 63px;
        }
    }

    &__title {
        color: $color-tondelier-green;
        font-size: 28px;
        font-weight: $font-weight-bold;
        letter-spacing: -0.02em;
        line-height: 1.03;
        margin-bottom: 11px;
    }

    &__text {
        @include text();
        margin: 0 auto;
        margin-bottom: 32px;
        max-width: 760px;
    }

    &__readmore {
        @include button-green;
    }
}