.search-filter {
    padding-top: 50px;
    padding-bottom: 50px;
    @include padding-container;

    @include for-tablet-portrait-up {
        padding-top: 50px;
        padding-bottom: 98px;
    }

    &__title {
        color: $color-blue;
        font-size: 18px;
        font-weight: $font-weight-bold;
        line-height: 1.16;
        text-align: center;
        margin-bottom: 24px;
    }

    &__form {
        display: flex;
        flex-direction: column;

        @include for-tablet-landscape-up {
            justify-content: center;
            flex-direction: row;
        }

        > *:not(:last-child) {
            margin-bottom: 2px;

            @include for-tablet-landscape-up {
                margin-bottom: 0;
                margin-right: 2px;
            }
        }

        &__select,
        &__button {
            min-width: 156px;
        }

        &__select {
            appearance: none;
            outline: none;
            border: none;
            border-radius: 0;
            background-color: $color-grey4;
            padding: 18px 20px;
            padding-right: 37px;
            font-size: 13px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: $color-blue;
            cursor: pointer;
            background-image: url("/assets/keizerpoort/img/icon-select.svg");
            background-repeat: no-repeat;
            background-position: center right 17px;
        }

        &__button {
            @include button-orange;
            padding: 18px 20px;
        }
    }
}
