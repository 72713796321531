@mixin double-arrow($direction, $margin-bottom: 22px) {
    &::before {
        content: "";
        display: block;
        background-repeat: no-repeat;
    }

    @if ($direction == "down") {
        &::before {
            width: 13px;
            height: 18px;
            background-image: url("/assets/keizerpoort/img/icon-double-arrow-down.svg");
            margin-bottom: $margin-bottom;
        }
    }
    @else if ($direction == "left" or $direction == "right") {
        &::before {
            width: 18px;
            height: 13px;
            background-image: url("/assets/keizerpoort/img/icon-double-arrow-#{$direction}.svg");
            margin-bottom: $margin-bottom;
        }
    }
    @else {
        @error "Direction '#{$direction}' does not exist.";
    }
}
