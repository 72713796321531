.form-modal {
    $modal-position-bottom: 0px;
    $modal-position-right: 57px;
    $modal-padding-x: 27px;
    $modal-padding-y: 30px;
    $modal-button-height: 40px;
    $modal-width: 323px;

    position: fixed;
    z-index: $z-form-modal;
    bottom: $modal-position-bottom;
    top: 0;
    left: 0;
    right: 0;

    @include for-tablet-portrait-up {
        width: $modal-width;
        left: auto;
        top: auto;
        right: $gutter-tablet;
    }

    @include for-desktop-up {
        right: $modal-position-right;
    }

    @media screen and (min-width: $container-width) {
        right: calc((100vw / 2 - #{$container-width} / 2) + #{$modal-position-right} - 8px);
    }

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: transform $anim-duration ease-in-out;
    pointer-events: none;

    .modal {
        transition: opacity $anim-duration ease-in-out, visibility $anim-duration ease-in-out,
            transform $anim-duration ease-in-out;
        padding: $modal-padding-y $modal-padding-x;
        background-color: $color-blue;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transform: translateY(0) translateY(#{$modal-button-height});
        overflow-y: auto;

        @include for-tablet-portrait-up {
            overflow: visible;
        }

        &__close {
            appearance: none;
            border: none;
            box-shadow: none;
            padding: 0;
            margin: 0;
            display: inline-block;
            position: absolute;
            top: $modal-padding-y;
            right: $modal-padding-x;
            outline: none;
            cursor: pointer;
            transition: opacity 0.15s ease-in-out;

            &:hover {
                opacity: 0.6;
            }
        }

        &__open {
            position: absolute;
            bottom: 0;
            right: 0;
            pointer-events: auto;
            padding: 12px 26px;
            background-color: $color-orange;
            transition: opacity $anim-duration ease-in-out, transform $anim-duration ease-in-out;

            color: $color-white;
            font-size: 14px;
            font-family: $font-base;
            font-weight: $font-weight-bold;
            text-decoration: none;
            text-transform: uppercase;

            svg {
                display: inline-block;
                fill: currentColor;
                margin-left: 10px;
            }
        }

        &__body {
            .form__success {
                margin-top: 12px;
            }
        }

        &__title {
            display: inline-block;
            color: $color-white;
            font-size: 14px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            line-height: 1.5;
            letter-spacing: 0.04em;
            margin-bottom: 14px;
            margin-right: 30px;
        }

        &__text {
            color: $color-white;
            font-size: 14px;
            font-weight: $font-weight-regular;
            margin-bottom: 20px;
            margin-right: 30px;
        }

        &__form {
            @include freeform();

            .form__label {
                margin-bottom: 8px;
            }
        }
    }

    &--open {
        pointer-events: auto;

        .modal {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);

            &__open {
                opacity: 0;
                transform: translateY(#{$modal-button-height});
            }
        }
    }
}
