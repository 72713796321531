// Colors //
// Orange
$color-orange: #f68700; // Default brand orange
$color-orange2: #ffb760;
$color-orange3: #ffdbae;
$color-orange4: #ffedd7;

// Blue
$color-blue: #00287a; // Default brand blue
$color-blue2: #5d65aa;
$color-blue3: #aaacd7;
$color-blue4: #d3d4ec;
$color-blue5: #e7e8f6;
$color-blue6: #f0f1f9;

// Other brand
$color-tondelier-green: #359f5a;
$color-tondelier-red: #ff0000;
$color-error: #ff0000;

// Black and white
$color-black: #2e2e2e;
$color-grey1: #535353;
$color-grey2: #888888;
$color-grey3: #b9b9b9;
$color-grey4: #ebebeb;
$color-white: #fff;

// Units
$color-building1: $color-blue2;
$color-building2: $color-blue;
$color-building3: $color-orange2;
$color-building4: $color-orange;
$color-building5: $color-blue3;

// Fonts //
$font-base: europa, sans-serif;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-bold: 700;

// Container + sizing //
$gutter-mobile: 20px;
$gutter-tablet: 50px;
$gutter-desktop: 140px;
$container-width: 1900px;

// Transitions //
$anim-duration-link: 0.15s;
$anim-duration: 0.3s;

// Header //
$header-main-height: 125px;

// Z-index
$z-header-main: 100;
$z-form-modal: 101;