// @import "/node_modules/@keplerstein/keplerstein-component-library/scss/base";
@import "@keplerstein/keplerstein-component-library/scss/base";
@import "@keplerstein/keplerstein-component-library/scss/container";
@import "@keplerstein/keplerstein-component-library/scss/button";
@import "@keplerstein/keplerstein-component-library/scss/card-grid";

@import "swiper/src/swiper";
@import "swiper/src/components/effect-fade/effect-fade";

@import "vars";

@import "mixins/typo";
@import "mixins/container";
@import "mixins/form";
@import "mixins/video";
@import "mixins/swiper";
@import "mixins/object-fit";
@import "mixins/building";
@import "mixins/double-arrow";

@import "components/photoswipe.scss";
@import "components/photoswipe-ui.scss";
@import "components/contact-poi";
@import "components/building-map";
@import "components/footer";
@import "components/header";
@import "components/search-filter";
@import "components/apartment-grid";
@import "components/tondelier-cta";
@import "components/photo-swiper";
@import "components/form-modal";
// Pages //
// Singles
@import "singles/about";
@import "singles/contact";
@import "singles/home";
@import "singles/in-the-picture";
@import "singles/text-page";

// Story 
@import "stories/list";
@import "stories/entry";

// Offer
@import "offer/list";
@import "offer/entry";
@import "offer/building";

html {
    font-family: $font-base;
    font-weight: $font-weight-light;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: $color-grey4;

    &:not(.page--homeKeizerpoort) {
        padding-top: $header-main-height;
    }

    &.page--homeKeizerpoort {
        padding-top: $header-main-height;

        @include for-tablet-landscape-up {
            padding-top: 0;
        }
    }

    &.mobile-nav-open {
        overflow: hidden;

        @include for-tablet-landscape-up {
            overflow: visible;
        }
    }

    &.modal-open {
        overflow: hidden;

        @include for-tablet-portrait-up {
            overflow: visible;
        }
    }
}

#wrapper,
.container {
    width: 100%;
    max-width: $container-width;
    margin: 0 auto;
}

#wrapper {
    background-color: $color-white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $font-weight-bold;
}

address {
    font-style: normal;
}

.cc-message {
    font-family: $font-base;
    font-weight: $font-weight-light;

    a {
        color: $color-blue;
    }
}

.cc-compliance {
    font-family: $font-base;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}