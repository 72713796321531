.page--storiesKeizerpoort {
    .story-header {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row-reverse;
        }

        &__image,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }
        &__image {
            background-color: $color-grey4;

            img {
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            background-color: $color-orange4;
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet * 2 $gutter-tablet;
            }

            @include for-desktop-up {
                // padding-left: $gutter-desktop;
                padding: 75px 87px;
            }
        }

        // Typo

        &__title {
            @include heading1;
            @include double-arrow($direction: down);
            margin-bottom: 19px;

            @include for-tablet-portrait-up {
                max-width: 320px;
            }
        }

        &__text {
            @include body-text;
            @include for-tablet-portrait-up {
                max-width: 353px;
            }
        }
    }

    .story-back {
        @include padding-container;
        padding-top: 33px;

        &__link {
            @include backLink();
        }
    }

    .story-body {
        display: flex;
        flex-direction: column;

        @include for-tablet-portrait-up {
            flex-direction: row;
        }

        @include padding-container;
        padding-top: 70px;

        &__text,
        &__images {
            @include for-tablet-portrait-up {
                width: 50%;
                padding-left: $gutter-tablet;
                padding-right: $gutter-tablet;
            }

            @include for-desktop-up {
                padding-left: 93px;
                padding-right: 93px;
            }
        }

        &__text {
            &__inner {
                @include body-text;
                @include double-arrow($direction: right, $margin-bottom: 24px);
                max-width: 357px;
            }
        }

        &__images {
            a {
                display: block;
                margin-top: 20px;

                @include for-tablet-portrait-up {
                    margin-top: 0;
                }

                + a {
                    margin-top: 20px;

                    @include for-tablet-portrait-up {
                        margin-top: 80px;
                    }
                }

                figure {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .photo-swiper {
        padding-top: 30px;
        padding-bottom: 30px;

        @include for-tablet-portrait-up {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @include for-tablet-landscape-up {
            padding-bottom: 125px;
        }
    }

    .story-pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;

        @include for-tablet-portrait-up {
            padding-top: 0;
            padding-bottom: 125px;
        }

        &__prev,
        &__back,
        &__next {
            display: inline-block;
            font-size: 13px;
            font-weight: $font-weight-regular;
            text-transform: uppercase;
            text-decoration: none;
            color: $color-blue;
            transition: all $anim-duration-link ease-in-out;
            text-align: center;
            outline: none;

            &:hover {
                color: $color-black;
            }
        }

        &__prev,
        &__next {
            border: 1px solid $color-blue;
            padding: 15px 20px;

            &:hover {
                background-color: $color-blue;
                color: $color-white;
            }

            &--disabled {
                opacity: 0.5;
                cursor: not-allowed;

                &:hover {
                    background-color: transparent;
                    color: $color-blue;
                }
            }

            @include for-tablet-portrait-up {
                text-align: left;
            }
        }

        &__prev {
            @include for-tablet-portrait-up {
                padding-left: 102px;
            }
            border-left: none;
        }

        &__back {
        }

        &__next {
            @include for-tablet-portrait-up {
                padding-right: 102px;
            }
            border-right: none;
        }
    }
}
