.building-map {
    @include padding-container;
    padding-top: 60px;

    @include for-tablet-portrait-up {
        padding-top: 67px;
    }

    &--prompto {
        padding-left: $gutter-mobile;
        padding-right: $gutter-mobile;
    }

    &__title {
        color: $color-blue;
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        line-height: 1.17;
        margin-bottom: 20px;

        @include for-tablet-portrait-up {
            margin-bottom: 48px;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__image {
        &--mobile {
            @include for-tablet-portrait-up {
                display: none;
            }
        }
        img {
            width: 100%;
        }
    }

    &__frame {
        display: none;
        position: relative;
        padding-top: 56.25%;
        @include for-tablet-portrait-up {
            display: block;
        }
        iframe {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &__pins {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .building-map-pin {
        position: absolute;
        top: 0;
        left: 0;

        &__marker {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: $font-weight-bold;
            width: 25px;
            height: 25px;
            background-color: $color-blue;
            color: $color-white;
            transition: background-color $anim-duration ease-in-out;
            text-decoration: none;

            @include for-tablet-landscape-up {
                font-size: 25px;
                width: 37px;
                height: 37px;
            }
        }

        &__box {
            display: none;
            
            @include for-tablet-landscape-up {
                display: block;
                position: relative;
                z-index: 1;
                background-color: white;
                opacity: 0;
                visibility: hidden;
                height: 0;
                width: 0;
            }
        }

        // Box contents

        &__image {
            img {
                width: 100%;
            }
        }

        &__body {
            padding: 20px 10px;
        }

        // Typo
        &__building {
            @include building-label;
        }

        &__title {
            @include heading3;
            margin-bottom: 10px;
        }

        &__text {
            color: $color-blue;
            font-size: 13px;
            font-weight: $font-weight-regular;
            line-height: 1.61;
        }

        &:hover {
            .building-map-pin {
                &__marker {
                    background-color: $color-orange;
                }

                @include for-tablet-landscape-up {
                    &__box {
                        opacity: 1;
                        visibility: visible;
                        max-height: none;
                        max-width: none;
                        margin-top: 24px;
                        width: 225px;
                        height: auto;
                        padding: 8px;
                    }
                }
            }
        }

        &--gebouw1 {
            .building-map-pin__building {
                color: $color-building1;
            }
        }

        &--gebouw2 {
            .building-map-pin__building {
                color: $color-building2;
            }
        }

        &--gebouw3 {
            .building-map-pin__building {
                color: $color-building3;
            }
        }

        &--gebouw4 {
            .building-map-pin__building {
                color: $color-building4;
            }
        }

        &--gebouw5 {
            .building-map-pin__building {
                color: $color-building5;
            }
        }
    }
}
