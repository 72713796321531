.page--commercialSpacesKeizerpoort,
.page--housesKeizerpoort,
.page--buildingsKeizerpoort {
    .building-header {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row-reverse;
        }

        &__slider,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }
        &__slider {
            background-color: $color-grey4;

            img {
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            background-color: $color-blue6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet * 2 $gutter-tablet;
            }

            @include for-desktop-up {
                // padding-left: $gutter-desktop;
                padding: 75px 87px;
            }
        }

        &__slider {
            .swiper-container,
            .swiper-slide,
            .swiper-slide > a {
                height: 100%;
            }

            .swiper-slide {
                a {
                    display: block;
                }

                video,
                img {
                    width: 100%;
                    height: 100%;
                    @include object-fit(cover);
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: 1;
                width: 50px;

                @include for-tablet-portrait-up {
                    width: 100px;
                }
            }

            .swiper-button-prev {
                left: 0;
                @include swiper-button-prev;
            }

            .swiper-button-next {
                right: 0;
                @include swiper-button-next;
            }
        }

        // Typo

        &__building {
            @include building-label;
        }

        &__title {
            @include heading1;
            margin-bottom: 19px;

            @include for-tablet-portrait-up {
                max-width: 320px;
            }
        }

        &__text {
            @include body-text;
            @include for-tablet-portrait-up {
                max-width: 353px;
            }
        }

        &--gebouw1 {
            .building-header__building {
                color: $color-building1;
            }
        }

        &--gebouw2 {
            .building-header__building {
                color: $color-building2;
            }
        }

        &--gebouw3 {
            .building-header__building {
                color: $color-building3;
            }
        }

        &--gebouw4 {
            .building-header__building {
                color: $color-building4;
            }
        }

        &--gebouw5 {
            .building-header__building {
                color: $color-building5;
            }
        }
    }

    .building-back {
        @include padding-container;
        padding-top: 33px;
        padding-bottom: 33px;

        &__link {
            @include backLink();
        }
    }

    .building-image-text {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 0;
        padding-bottom: 60px;

        @include for-tablet-portrait-up {
            flex-direction: row-reverse;
            padding-top: 60px;
            padding-bottom: 140px;
        }

        @include padding-container;

        &__body,
        &__image {
            @include for-tablet-portrait-up {
                width: 50%;
                padding-left: $gutter-tablet;
                padding-right: $gutter-tablet;
            }

            @include for-desktop-up {
                padding-left: 93px;
                padding-right: 93px;
            }
        }

        &__body {
            &__inner {
                @include body-text;
                max-width: 357px;

                h2 {
                    @include double-arrow($direction: left, $margin-bottom: 24px);
                }
            }
        }

        &__image {
            margin-bottom: 30px;

            @include for-tablet-portrait-up {
                margin-bottom: 0;
            }

            a {
                display: block;

                img {
                    width: 100%;
                }
            }
        }
    }

    .building-text-image-orange {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row;
        }

        &__image,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }

        &__image {
            background-color: $color-grey4;

            img {
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            background-color: $color-orange4;
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet * 2 $gutter-tablet;
                padding-left: $gutter-tablet * 2;
            }

            @include for-desktop-up {
                padding: 75px 87px;
                padding-left: $gutter-desktop + 87px;
            }

            &__inner {
                @include body-text;
                @include double-arrow($direction: right);
                @include for-tablet-portrait-up {
                    max-width: 353px;
                }
            }
        }
    }

    .search-filter {
        @include for-tablet-portrait-up {
            padding-top: 119px;
        }
    }
    .featured-story {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row-reverse;
        }

        &__image,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }
        &__image {
            background-color: $color-grey4;

            img {
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            background-color: $color-blue6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet;
            }

            @include for-desktop-up {
                // padding-left: $gutter-desktop;
                padding: 67px 87px;
            }
        }

        // Typo

        &__pretitle {
            @include double-arrow($direction: left, $margin-bottom: 30px);
            color: $color-blue;
            font-size: 13px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            line-height: 1.38;
            margin-bottom: 24px;
        }

        &__title {
            @include heading1;
            margin-bottom: 24px;

            @include for-tablet-portrait-up {
                max-width: 316px;
            }
        }

        &__text {
            @include text($color: $color-blue);
            margin-bottom: 40px;

            @include for-tablet-portrait-up {
                max-width: 266px;
            }
        }

        &__readmore {
            @include button-orange();
        }
    }
}
