
@mixin padding-container {
    padding-left: $gutter-mobile;
    padding-right: $gutter-mobile;

    @include for-tablet-portrait-up {
        padding-left: $gutter-tablet;
        padding-right: $gutter-tablet;
    }

    @include for-desktop-up {
        padding-left: $gutter-desktop;
        padding-right: $gutter-desktop;
    }
}

@mixin padding-container-left {
    padding-left: $gutter-mobile;

    @include for-tablet-portrait-up {
        padding-left: $gutter-tablet;
    }

    @include for-desktop-up {
        padding-left: $gutter-desktop;
    }
}

@mixin padding-container-right {
    padding-right: $gutter-mobile;

    @include for-tablet-portrait-up {
        padding-right: $gutter-tablet;
    }

    @include for-desktop-up {
        padding-right: $gutter-desktop;
    }
}

@mixin margin-container-left {
    margin-left: $gutter-mobile;

    @include for-tablet-portrait-up {
        margin-left: $gutter-tablet;
    }

    @include for-desktop-up {
        margin-left: $gutter-desktop;
    }
}

@mixin margin-container-right {
    margin-right: $gutter-mobile;

    @include for-tablet-portrait-up {
        margin-right: $gutter-tablet;
    }

    @include for-desktop-up {
        margin-right: $gutter-desktop;
    }
}