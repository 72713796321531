@mixin freeform($columns: false) {
    @if ($columns) {
        .form__wrapper {
            @include for-tablet-portrait-up {
                column-count: 2;
                column-gap: 25px;
            }
        }
    }

    .ff-form-errors,
    .ff-form-success,
    .form__success {
        display: none;
    }

    .form__success {
        font-size: 14px;
        color: $color-white;
    }

    .ff-errors {
        display: none; // hide for now
        color: $color-error;
        font-family: $font-base;
        font-weight: $font-weight-regular;
        font-size: 10px;
        margin-top: 4px;
    }

    .form__row {
        @if ($columns) {
            break-inside: avoid;
        }
        + .form__row {
            margin-top: 17px;
        }
    }

    .form__input {
        color: $color-blue;
        font-family: $font-base;
        font-weight: $font-weight-regular;
        font-size: 13px;
        background-color: $color-white;
        border: 1px solid $color-white;
        padding: 7px 9px;
        transition: color $anim-duration-link ease-in-out;
        width: 100%;
        appearance: none;
        border-radius: 0;
        // border: none;
        outline: none;

        &.ff-has-errors {
            color: $color-error;
            border-color: $color-error;
            @include placeholder($color-error);
        }

        @include placeholder($color-blue3);
    }

    // select.form__input {
    //     appearance: none;
    //     border-radius: 0;
    //     background-image: url("/img/icon-form-select.svg");
    //     background-repeat: no-repeat;
    //     background-position: center right 18px;
    //     padding-right: 18px * 1.5 + 18px; // input offset right * modifier + width of icon
    // }

    .form__label {
        display: block;
        color: $color-white;
        font-family: $font-base;
        font-weight: $font-weight-bold;
        font-size: 14px;
        margin-bottom: 12px;
        line-height: 1.28;

        &:not(.form__label--required)::after {
            color: $color-blue4;
            font-size: 14px;
            font-weight: $font-weight-regular;
            content: " (optioneel)";
        }
    }

    .form__checkbox,
    .form__radio {
        $icon-size: 20px;
        $icon-size-checked: 12px;

        position: relative;
        user-select: none;

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            height: 0;
            width: 0;
            appearance: none;

            &:checked ~ label::after {
                transform: scale(1);
            }

            &.ff-has-errors {
                ~ label {
                    color: $color-error;

                    &::before {
                        border-color: $color-error;
                    }
                }

                ~ .ff-errors {
                    display: none;
                }
            }
        }

        label {
            display: inline-block;
            position: relative;
            color: $color-blue4;
            font-size: 12px;
            font-family: $font-base;
            font-weight: $font-weight-regular;
            padding-left: $icon-size + 13px;
            line-height: 1.75;
            transition: all $anim-duration-link ease-in-out;
            cursor: pointer;

            // @if ($columns) {
            //     @include for-tablet-portrait-up {
            //         max-width: 333px;
            //     }
            // }

            a {
                color: inherit;
                text-decoration: underline;

                &:hover {
                    color: $color-blue3;
                }
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
            }

            &::before {
                top: 1px;
                left: 0;
                height: $icon-size;
                width: $icon-size;
                background-color: transparent;
                border: 1px solid $color-blue4;
                transition: border-color $anim-duration-link ease-in-out;
            }

            &::after {
                top: 5px;
                left: 4px;
                height: $icon-size-checked;
                width: $icon-size-checked;
                background: $color-orange;
                transform: scale(0);
                transition: transform $anim-duration-link ease-in-out;
            }
        }
    }

    .form__checkbox {
        label {
        }
    }

    .form__radio {
        label {
            &::before,
            &::after {
                border-radius: 100%;
            }
        }
    }

    .form__submit {
        @include button-orange();
        outline: none;
        cursor: pointer;

        &[disabled] {
            cursor: not-allowed;
        }
    }

    .form__fieldset {
        // Disabled states for fieldset children
        transition: opacity $anim-duration ease-in-out;

        .form__wrapper {
        }

        &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;

            .form__wrapper {
                pointer-events: none;
            }

            .form__submit {
                cursor: default;

                &:hover {
                    border-bottom-color: $color-black;
                }
            }

            &.form__button {
                &[disabled] {
                    cursor: not-allowed;

                    &:hover {
                        background-color: $color-grey3;
                    }
                }
            }

            .form__radio,
            .form__checkbox {
                label {
                    cursor: default;
                }
            }
        }
    }
}

@mixin placeholder($color: $color-black) {
    &::placeholder {
        transition: color $anim-duration-link ease-in-out;
        color: $color;
    }

    &:-moz-placeholder {
        transition: color $anim-duration-link ease-in-out;
        color: $color;
        opacity: 1; //by default opacity is set lower on a placeholder in firefox
    }

    &::-moz-placeholder {
        transition: color $anim-duration-link ease-in-out;
        color: $color;
        opacity: 1; //by default opacity is set lower on a placeholder in firefox
    }

    &:-ms-input-placeholder {
        transition: color $anim-duration-link ease-in-out;
        color: $color;
    }

    &::-ms-input-placeholder {
        transition: color $anim-duration-link ease-in-out;
        color: $color;
    }
}
