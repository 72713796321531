@import "photoswipe/src/css/main";
@import "photoswipe/src/css/default-skin/default-skin";

.pswp__bg {
    opacity: 1 !important;
    background: $color-white;
}

.pswp__top-bar {
    // display: none;
    z-index: 1;

    background-color: transparent !important;
}

.pswp__img {
}

.pswp__button {
    position: relative;

    display: block;
    float: right;
    overflow: visible;

    margin: 0;
    padding: 0;

    cursor: pointer;
    transition: opacity 0.2s;

    opacity: 0.75;
    border: 0;
    background-color: transparent;
    box-shadow: none;

    -webkit-appearance: none;
    &--arrow--left {
        position: absolute;
        top: 50%;
        left: 0;

        visibility: visible;

        width: 70px;
        height: 100px;
        margin-top: -50px;

        background: 0 0;
        &:before {
            position: absolute;
            top: 35px;
            left: 50%;

            width: 14px;
            height: 25px;

            content: "";
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

            background-color: transparent;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.01' height='25.88' viewBox='0 0 14.01 25.88'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2351504f;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-prev%3C/title%3E%3Cg id='Laag_2' data-name='Laag 2'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cpolygon class='cls-1' points='12.94 25.88 0 12.96 13.03 0 14.01 0.98 1.95 12.96 13.92 24.9 12.94 25.88'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 14px 25px;
        }
    }
    &--arrow--right {
        position: absolute;
        top: 50%;
        right: 0;

        visibility: visible;

        width: 70px;
        height: 100px;
        margin-top: -50px;

        background: 0 0;
        &:before {
            position: absolute;
            top: 35px;
            right: 50%;

            width: 14px;
            height: 25px;

            content: "";
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

            background-color: transparent;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.01' height='25.88' viewBox='0 0 14.01 25.88'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2351504f;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-next%3C/title%3E%3Cg id='Laag_2' data-name='Laag 2'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cpolygon class='cls-1' points='0.97 25.88 0 24.9 12.05 12.92 0.09 0.98 1.06 0 14.01 12.92 0.97 25.88'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 14px 25px;
        }
    }
    &--zoom {
        display: none;
    }
}

.pswp__button--close {
    padding: 60px;

    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.97' height='26' viewBox='0 0 25.97 26'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2351504f;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eclose-icon%3C/title%3E%3Cg id='Laag_2' data-name='Laag 2'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cpolygon class='cls-1' points='13.96 13.02 25.97 1 25 0.03 13 12.02 0.97 0 0 0.97 12.01 12.98 0 25 0.98 25.97 12.97 13.97 25 26 25.98 25.02 13.96 13.02'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px 26px;
}
