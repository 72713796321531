@mixin video-icon() {
    position: relative;

    &::before {
        content: "";
        display: block;
        width: 56px;
        height: 56px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba($color-blue, 0.8);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.475' height='29.882' viewBox='0 0 23.475 29.882' fill='%23e7e8f6'%3E %3Cpath d='M454.5,2277.26v29.882l23.475-14.99Z' transform='translate(-454.5 -2277.26)' /%3E %3C/svg%3E ");
        background-size: 24px 30px;
        background-repeat: no-repeat;
        background-position: calc(50% + 3px) center;
        transition: background-color $anim-duration ease-in-out;
    }

    &:hover {
        &::before {
            background-color: rgba($color-orange, .8);
        }
    }
}