.page--contactKeizerpoort {
    .contact-header {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row-reverse;
        }

        &__image,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }

        &__image {
            background-color: $color-grey4;

            video,
            img {
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            display: flex;
            align-items: center;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet * 2 $gutter-tablet;
            }

            @include for-desktop-up {
                // padding-left: $gutter-desktop;
                padding: 75px 105px;
            }

            > div {
                @include for-tablet-portrait-up {
                    max-width: 290px;
                }
            }

            .logo {
                display: inline-block;
                margin-bottom: 20px;
            }

            .text {
                color: $color-black;
                font-size: 16px;
                font-weight: $font-weight-regular;
                line-height: 1.375;
                margin-bottom: 16px;

                a {
                    text-decoration: none;
                    color: inherit;
                    transition: color $anim-duration-link ease-in-out;

                    &:hover {
                        color: $color-blue;
                    }
                }
            }

            .small-text {
                font-size: 14px;
                font-weight: $font-weight-light;
                line-height: 1.5;
                margin-bottom: 20px;

                a {
                    color: $color-blue;
                    transition: color $anim-duration-link ease-in-out;
                    text-decoration: none;
        
                    &:hover {
                        color: $color-black;
                    }
                }

                > *:not(:last-child) {
                    margin-bottom: 1rem;
                }

                @include for-tablet-portrait-up {
                    max-width: 265px;
                }
            }

            .download {
                @include rounded-button-orange;
                margin-bottom: 37px;
            }

            .social-nav {
                &__list {
                    display: flex;
                }

                &__item {
                    &:not(:first-child) {
                        margin-left: 20px;
                    }

                    a {
                        display: flex;
                        width: 38px;
                        height: 38px;
                        background-color: $color-blue;
                        border-radius: 100%;
                        justify-content: center;
                        align-items: center;
                        transition: background-color $anim-duration-link ease-in-out;
                        
                        &:hover {
                            background-color: $color-black;
                        }
                    }

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    .contact-poi {
        // Overrides if needed
    }
}
