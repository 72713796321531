.apartment-grid {
    @include padding-container;
    padding-bottom: 60px;

    @include for-tablet-portrait-up {
        padding-bottom: 102px;
    }

    &__title {
        color: $color-blue;
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        line-height: 1.17;
        margin-bottom: 69px;
    }

    &__list {
        @include cardGrid(
            $defaultRowGutter: 20px,
            $breakpoints: (
                "for-tablet-portrait-up": (
                    "columns": 2,
                    "rowGutter": 37px,
                    "columnGutter": 30px
                ),
                "for-tablet-landscape-up": (
                    "columns": 3,
                    "rowGutter": 75px,
                    "columnGutter": 60px
                )
            )
        );
    }

    &__item {
        .apartment-card {
            display: block;
            text-decoration: none;

            &__image {
                position: relative;
                margin-bottom: 19px;

                img {
                    width: 100%;
                }

                &--temp {
                    
                }
            }

            &__body {
            }

            // Typo

            &__title,
            &__type,
            &__street,
            &__info {
                transition: color $anim-duration ease-in-out;
            }

            &__building {
                @include building-label();
            }

            &__title {
                @include heading3;
                margin-bottom: 3px;
            }

            &__type {
                // color: $color-black;
                @include intro;
                margin-bottom: 5px;
            }

            &__street {
                // color: $color-black;
                @include intro;
                margin-bottom: 5px;
            }

            &__info {
                // color: $color-black;
                @include text;
            }

            &__readmore {
                // opacity: 0;
                // color: $color-white;
                // background-color: $color-orange;
                color: $color-orange;
                border: 1px solid $color-orange;
                outline: none;
                transition: opacity $anim-duration ease-in-out;
            }

            &__readmore,
            &__in-option,
            &__sold {
                // position: absolute;
                // bottom: 0;
                // left: 0;
                margin-top: 20px;
                font-size: 14px;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                display: inline-block;
                padding: 13px 23px;
                user-select: none;
            }

            &__sold {
                background-color: $color-grey3;
                color: $color-white;
            }

            &__in-option {
                background-color: $color-grey3;
                color: $color-white;
            }

            &:not(.apartment-card--sold):not(.apartment-card--in-option):hover {
                .apartment-card {
                    &__title,
                    &__type,
                    &__street,
                    &__info {
                        color: $color-orange;
                    }

                    &__readmore {
                        opacity: 1;
                    }
                }
            }
            // Color modifiers

            &--gebouw1:not(.apartment-card--sold):not(.apartment-card--in-option) {
                .apartment-card__building {
                    color: $color-building1;
                }
            }

            &--gebouw2:not(.apartment-card--sold):not(.apartment-card--in-option) {
                .apartment-card__building {
                    color: $color-building2;
                }
            }

            &--gebouw3:not(.apartment-card--sold):not(.apartment-card--in-option) {
                .apartment-card__building {
                    color: $color-building3;
                }
            }

            &--gebouw4:not(.apartment-card--sold):not(.apartment-card--in-option) {
                .apartment-card__building {
                    color: $color-building4;
                }
            }

            &--gebouw5:not(.apartment-card--sold):not(.apartment-card--in-option) {
                .apartment-card__building {
                    color: $color-building5;
                }
            }
        }
    }
}
