.header {
    .header-main {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: $z-header-main;

        display: flex;
        flex-direction: column;
        height: $header-main-height;
        background-color: $color-white;
        border-bottom: 1px solid $color-grey4;

        @include for-tablet-landscape-up {
            transform: translateY(0);
            transition: transform $anim-duration ease-in-out;
        }

        .header-top {
            display: none;
            @include for-tablet-landscape-up {
                display: block;
            }
            & > .container {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                background-color: #F5F5F5;
                padding-left: $gutter-mobile;
                padding-right: $gutter-mobile;
                @include for-tablet-portrait-up {
                    padding-left: $gutter-tablet;
                    padding-right: $gutter-tablet;
                }
                @include for-desktop-up {
                    padding-left: 106px;
                    padding-right: 57px;
                }
                a {
                    display: block;
                    margin-left: 10px;
                    svg {
                        display: block;
                        height: 15px;
                        path {
                            fill: $color-grey3;
                            transition: fill 0.2s ease-in-out;
                        }
                    }
                    &:hover {
                        svg {
                            path {
                                fill: $color-grey1;
                            }
                        }
                    }
                }
            }
        }

        & > .container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: $gutter-mobile;
            padding-right: $gutter-mobile;

            @include for-tablet-portrait-up {
                padding-left: $gutter-tablet;
                padding-right: $gutter-tablet;
            }

            @include for-tablet-landscape-up {
                align-items: stretch;
            }

            @include for-desktop-up {
                padding-left: 106px;
                padding-right: 57px;
            }
        }

        .logo {
            @include for-tablet-portrait-up {
                align-self: center;
            }

            svg {
                width: 40px;
                height: 40px;
            }
        }

        .nav {
            position: fixed;
            top: $header-main-height;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: $color-white;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            opacity: 0;
            visibility: hidden;
            transition: opacity $anim-duration ease-in-out, visibility $anim-duration ease-in-out;

            @include for-tablet-landscape-up {
                position: static;
                top: auto;
                left: auto;
                bottom: auto;
                right: auto;
                background-color: transparent;
                opacity: 1;
                visibility: visible;
                overflow-y: visible;
                display: flex;
            }

            body.mobile-nav-open & {
                opacity: 1;
                visibility: visible;
            }
        }

        .main-nav {
            @include for-tablet-landscape-up {
                display: flex;
                align-items: stretch;
            }

            &__list {
                display: flex;
                flex-direction: column;

                @include for-tablet-landscape-up {
                    flex-direction: row;
                    align-items: stretch;
                }
            }

            &__item,
            &__button {
                > a {
                    text-decoration: none;
                }
            }

            &__item {
                @include for-tablet-landscape-up {
                    position: relative;
                    display: flex;
                    align-items: stretch;
                    margin-right: 42px;
                }

                > a {
                    display: block;
                    color: $color-blue;
                    font-size: 15px;
                    font-weight: $font-weight-regular;

                    padding: 20px 20px;
                    border-bottom: 1px solid $color-grey4;
                    transition: color $anim-duration-link;

                    @include for-tablet-portrait-up {
                        padding: 20px $gutter-tablet;
                    }

                    @include for-tablet-landscape-up {
                        display: flex;
                        align-items: stretch;
                        padding: 0;
                        border-bottom: none;
                    }

                    > span {
                        @include for-tablet-landscape-up {
                            align-self: center;
                        }
                    }
                }

                &:hover,
                &--active {
                    > a {
                        color: $color-orange;
                    }
                }

                &:hover {
                    > .sub-nav {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            &__button {
                @include for-tablet-landscape-up {
                    display: flex;
                }

                > a {
                    @include button(
                        $backgroundColor: $color-orange,
                        $color: $color-white,
                        $hoverBackgroundColor: $color-orange2,
                        $hoverColor: $color-white,
                        $padding: 11px 20px,
                        $fontSize: 16px,
                        $fontStack: $font-base,
                        $textTransform: uppercase
                    );

                    letter-spacing: -0.02em;
                    font-weight: $font-weight-bold;
                    width: 100%;

                    @include for-tablet-landscape-up {
                        width: auto;
                        align-self: center;
                    }
                }
            }
        }

        .sub-nav {
            // display: none;
            @include for-tablet-landscape-up {
                position: absolute;
                top: calc(100% - 15px);
                left: -20px;
                border: 1px solid $color-grey4;
                background-color: $color-white;
                width: 150px;
                opacity: 0;
                visibility: hidden;
                transition: opacity $anim-duration-link ease-in-out, visibility $anim-duration-link ease-in-out;
                z-index: 1;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 10px 10px;
                    left: 24px;
                    bottom: 100%;
                }

                &::before {
                    border-color: transparent transparent $color-grey4 transparent;
                }

                &::after {
                    border-color: transparent transparent $color-white transparent;
                    transform: translateY(1px);
                }
            }

            &__list {
                background-color: $color-grey4;

                @include for-tablet-landscape-up {
                    background-color: transparent;
                }
            }

            &__item {
                > a {
                    display: block;
                    text-decoration: none;
                    color: $color-blue;
                    font-size: 12px;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    transition: color $anim-duration-link;
                    padding: 12px 20px;

                    @include for-tablet-portrait-up {
                        padding: 12px $gutter-tablet;
                    }

                    @include for-tablet-landscape-up {
                        font-size: 13px;
                        padding: 8px 20px;
                    }
                }

                &:first-child {
                    > a {
                        @include for-tablet-landscape-up {
                            padding-top: 20px;
                        }
                    }
                }

                &:last-child {
                    > a {
                        @include for-tablet-landscape-up {
                            padding-bottom: 20px;
                        }
                    }
                }

                &:hover,
                &--active {
                    > a {
                        color: $color-orange;
                    }
                }
            }
        }

        .toggler {
            position: relative;
            display: block;
            width: 24px;
            height: 18px;
            cursor: pointer;
            transform: rotate(0deg);
            transition: $anim-duration ease-in-out;
            // z-index: $z-index-header + 2;

            &:focus {
                outline: none;
            }

            @include for-tablet-landscape-up {
                display: none;
            }

            span {
                display: block;
                position: absolute;
                height: 2px;
                width: 100%;
                margin: 0;
                background: $color-blue;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: $anim-duration ease-in-out;

                &:nth-child(1) {
                    top: 0px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    top: 8px;
                }

                &:nth-child(4) {
                    top: 16px;
                }
            }

            body.mobile-nav-open & {
                span:nth-child(1) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }

                span:nth-child(2) {
                    transform: rotate(45deg);
                }

                span:nth-child(3) {
                    transform: rotate(-45deg);
                }

                span:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }

        @include for-tablet-landscape-up {
            &--scrolled {
                transform: translateY(-100%);
            }
        }
    }
}
