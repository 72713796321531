.page--aboutKeizerpoort {
    .about-header {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row-reverse;
        }

        &__image,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }
        &__image {
            background-color: $color-grey4;

            a {
                display: block;
                height: 100%;
            }

            video,
            img {
                display: block;
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            background-color: $color-orange4;
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet * 2 $gutter-tablet;
            }

            @include for-desktop-up {
                // padding-left: $gutter-desktop;
                padding: 75px 87px;
            }
        }

        // Typo

        &__title {
            @include heading1;
            @include double-arrow($direction: down);
            margin-bottom: 19px;

            @include for-tablet-portrait-up {
                max-width: 320px;
            }
        }

        &__text {
            @include body-text;
            @include for-tablet-portrait-up {
                max-width: 353px;
            }
        }
    }

    .about-back {
        @include padding-container;
        padding-top: 33px;
        padding-bottom: 30px;

        &__link {
            @include backLink();
        }
    }

    .about-text-columns {
        display: flex;
        flex-direction: column;
        @include padding-container;
        padding-top: 0;
        padding-bottom: 60px;

        @include for-tablet-portrait-up {
            flex-direction: row;
            padding-top: 40px;
            padding-bottom: 147px;
        }

        &__left,
        &__right {
            @include for-tablet-portrait-up {
                width: 50%;
                padding-left: $gutter-tablet;
                padding-right: $gutter-tablet;
            }

            @include for-desktop-up {
                padding-left: 93px;
                padding-right: 93px;
            }

            &__inner {
                @include body-text;
                @include for-tablet-portrait-up {
                    max-width: 357px;
                }
            }
        }

        &__left {
            margin-bottom: 30px;

            @include for-tablet-portrait-up {
                margin-bottom: 0;
            }

            @include for-medium-desktop-up {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .about-text-image-orange {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row;
        }

        &__image,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }

        &__image {
            background-color: $color-grey4;

            a {
                display: block;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            background-color: $color-orange4;
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet * 2 $gutter-tablet;
                padding-left: $gutter-tablet * 2;
            }

            @include for-desktop-up {
                padding: 75px 87px;
                padding-left: $gutter-desktop + 87px;
            }

            &__inner {
                @include body-text;
                @include double-arrow($direction: right);
                @include for-tablet-portrait-up {
                    max-width: 353px;
                }
            }
        }
    }

    .about-text-images {
        @include padding-container;
        padding-top: 60px;
        padding-bottom: 60px;

        @include for-tablet-portrait-up {
            padding-top: 135px;
            padding-bottom: 147px;
        }

        &__image {
            padding-top: $gutter-mobile;

            @include for-tablet-portrait-up {
                padding-top: 0;
            }

            a {
                display: block;
            }

            img {
                width: 100%;
            }
        }

        &__body {
            &__inner {
                @include body-text;
                @include for-tablet-portrait-up {
                    max-width: 357px;
                }
            }
        }

        &__image,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }

        &__top {
            display: flex;
            flex-direction: column;

            @include for-tablet-portrait-up {
                flex-direction: row-reverse;
            }

            .about-text-images {
                &__image,
                &__body {
                    @include for-tablet-portrait-up {
                        padding-left: $gutter-tablet;
                        padding-right: $gutter-tablet;
                    }

                    @include for-desktop-up {
                        padding-left: 93px;
                        padding-right: 93px;
                    }

                    &__inner {
                        @include double-arrow($direction: left);
                    }
                }
            }
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            padding-top: 30px;

            @include for-tablet-portrait-up {
                flex-direction: row;
                padding-top: 134px;
            }

            .about-text-images {
                &__body {
                    @include for-tablet-portrait-up {
                        padding-left: $gutter-tablet;
                        padding-right: $gutter-tablet;
                    }

                    @include for-desktop-up {
                        padding-left: 93px;
                        padding-right: 93px;
                    }

                    &__inner {
                        @include double-arrow($direction: right);
                    }
                }
            }
        }
    }

    .about-image-text-blue {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row-reverse;
        }

        &__image,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }

        &__image {
            background-color: $color-grey4;

            a {
                display: block;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            background-color: $color-blue6;
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet * 2 $gutter-tablet;
                padding-right: $gutter-tablet * 2;
            }

            @include for-desktop-up {
                padding: 75px 87px;
                padding-right: $gutter-desktop + 87px;
            }

            &__inner {
                @include body-text;
                @include double-arrow($direction: left);
                @include for-tablet-portrait-up {
                    max-width: 353px;
                }
            }
        }
    }

    .photo-swiper {
        padding-top: 0;
        padding-bottom: 0;

        @include for-tablet-portrait-up {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @include for-tablet-landscape-up {
            padding-top: 140px;
            padding-bottom: 140px;
        }
    }
}
