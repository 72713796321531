.page--keizerpoortInThePicture {
    .pictures-overview {
        padding-top: 60px;
        padding-bottom: 97px;

        @include padding-container;

        @include for-tablet-portrait-up {
            padding-top: 67px;
        }

        &__title {
            color: $color-blue;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            line-height: 1.17;
            margin-bottom: 20px;

            @include for-tablet-portrait-up {
                margin-bottom: 69px;
            }
        }

        &__filters {
            margin: 0 auto;
            margin-bottom: 60px;

            max-width: 650px;

            .filters {
                $filter-gutter: 9px;

                &__list {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-top: -$filter-gutter;
                    margin-left: -$filter-gutter;
                }

                &__item {
                    padding-top: $filter-gutter;
                    padding-left: $filter-gutter;

                    a {
                        display: inline-block;
                        outline: none;
                        font-size: 13px;
                        font-weight: $font-weight-bold;
                        text-decoration: none;
                        text-transform: uppercase;
                        color: $color-grey2;
                        border: 1px solid $color-grey2;
                        padding: 10px 20px;
                        transition: color $anim-duration-link ease-in-out,
                            background-color $anim-duration-link ease-in-out,
                            border-color $anim-duration-link ease-in-out;
                    }

                    &:hover a {
                        background-color: $color-grey3;
                        border-color: $color-grey3;
                        color: $color-white;
                    }

                    &--active {
                        a {
                            background-color: $color-grey2;
                            border-color: $color-grey2;
                            color: $color-white;
                        }
                        &:hover a {
                            background-color: $color-grey1;
                            border-color: $color-grey1;
                            color: $color-white;
                        }
                    }
                }
            }
        }

        &__footage {
            .footage {
                &__list {
                    @include cardGrid(
                        $defaultRowGutter: 20px,
                        $breakpoints: (
                            "for-tablet-portrait-up": (
                                "columns": 2,
                                "rowGutter": 24px,
                                "columnGutter": 24px
                            ),
                            "for-tablet-landscape-up": (
                                "columns": 3,
                                "rowGutter": 24px,
                                "columnGutter": 24px
                            )
                        )
                    );
                }

                &__item {
                    a {
                        display: block;
                    }

                    img {
                        width: 100%;
                    }

                    iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
