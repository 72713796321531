@mixin swiper-button-base() {
    & {
        cursor: pointer;
        outline: none;
    }

    &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: $color-blue;
        background-repeat: no-repeat;
        background-position: center;
        transition: background-color $anim-duration ease-in-out;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    &:hover {
        &::before {
            background-color: $color-orange;
        }
    }
}

@mixin swiper-button-prev() {
    @include swiper-button-base();
    
    &::before {
        left: 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='18' viewBox='0 0 10.38 17.94' fill='%23fff'%3E %3Cpolygon points='8.97 17.94 10.38 16.53 2.83 8.97 10.38 1.41 8.97 0 0 8.97 8.97 17.94' /%3E %3C/svg%3E");
    }
}

@mixin swiper-button-next() {
    @include swiper-button-base();

    &::before {
        right: 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='18' viewBox='0 0 10.38 17.94' fill='%23fff'%3E %3Cpolygon points='1.41 17.94 0 16.53 7.56 8.97 0 1.41 1.41 0 10.38 8.97 1.41 17.94' /%3E %3C/svg%3E");
    }
}
