.page--appartmentsKeizerpoort {
    .apartment-content {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row-reverse;
        }

        &__left,
        &__right {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }

        &__left {
            img {
                width: 100%;
            }
        }

        &__image,
        &__video,
        &__iframe {
            background-color: $color-grey4;
        }

        &__image {
        }

        &__iframe {
            display: block;
            @include video-icon();
        }

        &__video {
            display: block;
            @include video-icon();
        }

        &__right {
            background-color: $color-white;
            // display: flex;
            // flex-direction: column;
            // align-items: flex-start;
            // justify-content: center;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet * 2 $gutter-tablet;
            }

            @include for-desktop-up {
                // padding-left: $gutter-desktop;
                padding: 75px 87px;
            }
        }

        &__building {
            @include building-label();
        }

        &__title {
            @include heading1;
            margin-bottom: 32px;
        }

        &__subtitle {
            @include heading3;
            display: inline-block;
            margin-bottom: 4px;
        }

        &__specs {
            max-width: 396px;

            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 14px 0;
                border-bottom: 1px solid $color-grey4;
                font-size: 15px;
                font-weight: $font-weight-light;
                color: $color-black;

                strong {
                    font-weight: $font-weight-regular;
                    font-size: 16px;
                }
            }
        }

        &__disclaimer {
            display: block;
            margin-top: 24px;
            color: $color-black;
            font-size: 13px;
            font-weight: $font-weight-light;
        }

        &__download {
            @include rounded-button-orange;
            margin-top: 40px;
        }

        &__back {
            @include padding-container-left;
            padding-top: 30px;
            padding-bottom: 30px;

            &__link {
                @include backLink();
            }
        }
        // Color modifiers

        &--gebouw1 {
            .apartment-content__building {
                color: $color-building1;
            }
        }

        &--gebouw2 {
            .apartment-content__building {
                color: $color-building2;
            }
        }

        &--gebouw3 {
            .apartment-content__building {
                color: $color-building3;
            }
        }

        &--gebouw4 {
            .apartment-content__building {
                color: $color-building4;
            }
        }

        &--gebouw5 {
            .apartment-content__building {
                color: $color-building5;
            }
        }
    }

    .photo-swiper {
        padding-top: 0;
        padding-bottom: 0;

        @include for-tablet-portrait-up {
            padding-bottom: 60px;
        }

        @include for-tablet-landscape-up {
            padding-bottom: 96px;
        }
    }

    .apartment-form {
        @include padding-container;
        padding-top: 60px;
        padding-bottom: 60px;

        @include for-tablet-portrait-up {
            padding-top: 116px;
            padding-bottom: 116px;
        }

        @include freeform($columns: true);
        background-color: $color-blue;

        &__title {
            color: $color-white;
            font-size: 28px;
            font-weight: $font-weight-bold;
            letter-spacing: -0.02em;
            text-align: center;
            margin-bottom: 5px;
        }

        &__text {
            @include text($color: $color-white);
            text-align: center;
            max-width: 326px;
            margin: 0 auto;
            margin-bottom: 40px;
        }

        &__form {
            max-width: 740px;
            margin: 0 auto;
        }

        .form__success {
            text-align: center;
        }
    }
}
