.page--homeKeizerpoort {
    .home-hero {
        position: relative;

        // Orange before
        // @include for-desktop-up {
        //     &::before {
        //         content: "";
        //         position: absolute;
        //         background: $color-orange;
        //         top: 0;
        //         bottom: 0;
        //         width: 100%;
        //         right: 100%;
        //     }
        // }

        // Orange parralelogram

        &__socials {
            display: none;
            @include for-tablet-landscape-up {
                display: block;
            }
            & > .container {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                background-color: #F5F5F5;
                padding-left: $gutter-mobile;
                padding-right: $gutter-mobile;
                @include for-tablet-portrait-up {
                    padding-left: $gutter-tablet;
                    padding-right: $gutter-tablet;
                }
                @include for-desktop-up {
                    padding-left: 106px;
                    padding-right: 57px;
                }
                a {
                    display: block;
                    margin-left: 10px;
                    svg {
                        display: block;
                        height: 15px;
                        path {
                            fill: $color-grey3;
                            transition: fill 0.2s ease-in-out;
                        }
                    }
                    &:hover {
                        svg {
                            path {
                                fill: $color-grey1;
                            }
                        }
                    }
                }
            }
        }

        &__wrapper {
            position: relative;
            @include for-desktop-up {
                overflow: hidden;

                &::before {
                    content: "";
                    position: absolute;
                    transform-origin: bottom right;
                    background: $color-orange;
                    top: 0;
                    bottom: 0;
                    left: 0%;
                    width: 55%;
                    transform: skew(31deg);
                }
            }
        }

        &-logo {
            display: none;

            @media (min-width: 1300px) {
                display: inline-block;
                position: absolute;
                top: 140px;
                transform: translateY(-50%);
                left: $gutter-desktop;
            }
        }

        &-nav {
            display: none;

            @include for-tablet-landscape-up {
                display: flex;
                height: 140px;
                background-color: $color-white;
                display: flex;
                justify-content: flex-end;
                align-items: stretch;
                padding-right: $gutter-tablet;

                @include for-desktop-up {
                    padding-right: 57px;
                }

                .main-nav {
                    display: flex;
                    align-items: stretch;

                    &__list {
                        display: flex;
                        flex-direction: row;
                        align-items: stretch;
                    }

                    &__item,
                    &__button {
                        > a {
                            text-decoration: none;
                        }
                    }

                    &__item {
                        position: relative;
                        display: flex;
                        align-items: stretch;
                        margin-right: 42px;

                        > a {
                            color: $color-blue;
                            font-size: 15px;
                            font-weight: $font-weight-regular;

                            border-bottom: 1px solid $color-grey4;
                            transition: color $anim-duration-link;

                            padding: 20px $gutter-tablet;
                            display: flex;
                            align-items: stretch;
                            padding: 0;
                            border-bottom: none;

                            > span {
                                align-self: center;
                            }
                        }

                        &:hover,
                        &--active {
                            > a {
                                color: $color-orange;
                            }
                        }

                        &:hover {
                            > .sub-nav {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }

                    &__button {
                        display: flex;

                        > a {
                            @include button(
                                $backgroundColor: $color-orange,
                                $color: $color-white,
                                $hoverBackgroundColor: $color-orange2,
                                $hoverColor: $color-white,
                                $padding: 11px 20px,
                                $fontSize: 16px,
                                $fontStack: $font-base,
                                $textTransform: uppercase
                            );

                            letter-spacing: -0.02em;
                            font-weight: $font-weight-bold;
                            width: auto;
                            align-self: center;
                        }
                    }
                }

                .sub-nav {
                    position: absolute;
                    top: calc(100% - 15px);
                    left: -20px;
                    border: 1px solid $color-grey4;
                    background-color: $color-white;
                    width: 150px;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity $anim-duration-link ease-in-out,
                        visibility $anim-duration-link ease-in-out;
                    z-index: 1;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 10px 10px 10px;
                        left: 24px;
                        bottom: 100%;
                    }

                    &::before {
                        border-color: transparent transparent $color-grey4 transparent;
                    }

                    &::after {
                        border-color: transparent transparent $color-white transparent;
                        transform: translateY(1px);
                    }

                    &__list {
                        background-color: $color-grey4;

                        @include for-tablet-landscape-up {
                            background-color: transparent;
                        }
                    }

                    &__item {
                        > a {
                            display: block;
                            text-decoration: none;
                            color: $color-blue;
                            font-weight: $font-weight-bold;
                            text-transform: uppercase;
                            transition: color $anim-duration-link;
                            font-size: 13px;
                            padding: 8px 20px;
                        }

                        &:first-child {
                            > a {
                                @include for-tablet-landscape-up {
                                    padding-top: 20px;
                                }
                            }
                        }

                        &:last-child {
                            > a {
                                @include for-tablet-landscape-up {
                                    padding-bottom: 20px;
                                }
                            }
                        }

                        &:hover,
                        &--active {
                            > a {
                                color: $color-orange;
                            }
                        }
                    }
                }
            }
        }

        &-content {
            background: $color-grey3;
            position: relative;
            display: flex;
            flex-direction: column-reverse;

            @include for-tablet-landscape-up {
                flex-direction: row;
            }

            @include for-desktop-up {
                position: static;
                display: block;
            }
        }

        &__image {
            @include for-tablet-landscape-up {
                width: 50%;
            }

            @include for-desktop-up {
                width: percentage(9/12);
                margin-left: percentage(3/12);
            }

            video,
            img {
                width: 100%;
                height: auto;

                @include for-tablet-landscape-up {
                    height: 600px;
                }
                // height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            background-color: $color-orange;
            // @include padding-container-left;

            padding: $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet;
            }

            @include for-tablet-landscape-up {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-top: 60px;
                padding-bottom: 60px;
                padding-right: 60px;
            }

            @include for-desktop-up {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                top: auto;
                width: auto;
                padding-left: $gutter-desktop;
                background-color: transparent;
            }
        }

        // Typo
        &__title {
            color: $color-blue;
            font-size: 32px;
            font-weight: $font-weight-bold;
            letter-spacing: -0.02em;
            line-height: 1.15;
            margin-bottom: 37px;

            @include for-tablet-landscape-up {
                max-width: 250px;
            }

            @media (min-width: 1300px) {
                max-width: 338px;
            }
        }

        &__preface {
            color: $color-white;
            font-size: 15px;
            font-weight: $font-weight-regular;
            line-height: 1.4;

            @include for-tablet-landscape-up {
                max-width: 350px;
            }

            @include for-desktop-up {
                max-width: 250px;
            }

            @media (min-width: 1300px) {
                max-width: 323px;
            }

            &:not(:last-child) {
                margin-bottom: 45px;
            }
        }

        &__flash {
            background-color: $color-blue;
            color: $color-white;
            padding: 24px 27px;

            @include for-tablet-landscape-up {
                max-width: 380px;
            }

            h2 {
                @include heading3($color: inherit);
            }

            p {
                font-size: 14px;
                font-weight: $font-weight-regular;
                color: inherit;
                line-height: 1.2;
                margin-top: 1rem;
            }
        }
    }

    .unit-map {
        &__title {
            text-align: left;

            @include for-tablet-portrait-up {
                margin-bottom: 30px;
            }
        }
    }

    .home-featured-apartments {
        @include margin-container-left;
        @include padding-container-right;
        overflow: hidden;
        padding-bottom: 120px;

        &__title {
            color: $color-blue;
            font-size: 18px;
            text-transform: uppercase;
            line-height: 1.17;
            margin-bottom: 30px;
        }

        &__slider {
            .swiper-container {
                overflow: visible;
            }

            @include for-desktop-up {
                .swiper-slide {
                    opacity: 0.5;
                    transition: opacity $anim-duration ease-in-out;
                }

                .swiper-slide-active,
                .swiper-slide-active + .swiper-slide,
                .swiper-slide-active + .swiper-slide + .swiper-slide {
                    opacity: 1;
                }
            }

            .swiper-button-next {
                position: absolute;
                top: 0;
                width: 50px;
                right: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;
                outline: none;

                > span {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    background-color: $color-blue;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: background-color $anim-duration ease-in-out;
                    top: 50%;
                    // transform: translateY(-50%);
                    z-index: 1;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='18' viewBox='0 0 10.38 17.94' fill='%23fff'%3E %3Cpolygon points='1.41 17.94 0 16.53 7.56 8.97 0 1.41 1.41 0 10.38 8.97 1.41 17.94' /%3E %3C/svg%3E");
                }

                &:hover {
                    span {
                        background-color: $color-orange;
                    }
                }

                @include for-tablet-portrait-up {
                    width: 50px;
                    right: -50px;
                }

                @include for-desktop-up {
                    width: 140px;
                    right: -140px;
                }

                > span {
                    @include for-tablet-landscape-up {
                        transform: translateX(-50%);
                    }

                    @include for-desktop-up {
                        left: 60px; // gutter
                    }
                }
            }
        }

        .ig-item {
            display: block;
            max-width: 300px;
            img {
                display: block;
                width: 100%;
            }
        }

        .apartment-card {
            display: block;
            text-decoration: none;

            &__image {
                position: relative;
                overflow: hidden;
                background-color: $color-grey4;
                margin-bottom: 20px;

                img {
                    width: 100%;
                }
            }

            &__body {
                max-width: 80%;
            }

            &__title,
            &__text {
                transition: color $anim-duration ease-in-out;
            }

            &__title {
                @include heading3;
                margin-bottom: 3px;
            }

            &__text {
                @include text($color: $color-blue);
            }

            &__readmore {
                position: absolute;
                bottom: 0;
                left: 0;
                font-size: 14px;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                display: inline-block;
                padding: 13px 23px;
                user-select: none;
                opacity: 0;
                color: $color-white;
                background-color: $color-orange;
                outline: none;
                transition: opacity $anim-duration ease-in-out;
            }
            &:hover {
                .apartment-card {
                    &__title,
                    &__text {
                        color: $color-orange;
                    }

                    &__readmore {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .home-about-info {
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-portrait-up {
            flex-direction: row-reverse;
        }

        &__image,
        &__body {
            @include for-tablet-portrait-up {
                width: 50%;
            }
        }
        &__image {
            background-color: $color-grey4;

            img {
                width: 100%;
                height: 100%;
                @include object-fit(cover);
            }
        }

        &__body {
            background-color: $color-orange4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            padding: $gutter-mobile * 2 $gutter-mobile;

            @include for-tablet-portrait-up {
                padding: $gutter-tablet * 2 $gutter-tablet;
            }

            @include for-desktop-up {
                // padding-left: $gutter-desktop;
                padding: 75px 87px;
            }
        }

        // Typo

        &__pretitle {
            display: inline-block;
            color: $color-blue;
            font-size: 13px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            line-height: 1.38;
            margin-bottom: 24px;
        }

        &__text {
            @include text($color: $color-blue);
            margin-bottom: 30px;
            margin-top: auto;

            @include for-medium-desktop-up {
                margin-top: 0;
            }

            h2 {
                @include double-arrow($direction: left, $margin-bottom: 24px);
                @include heading1;
                margin-bottom: 12px;

                @include for-tablet-portrait-up {
                    max-width: 176px;
                }
            }

            p {
                @include for-tablet-portrait-up {
                    max-width: 370px;
                }
            }
        }

        &__readmore {
            @include button-orange;
        }
    }

    .home-featured-stories {
        position: relative;
        background: $color-orange4;
        margin-top: 60px;

        @include for-tablet-landscape-up {
            @include padding-container-right;
            @include margin-container-left;
            margin-top: 136px;
            margin-bottom: 111px;
        }

        @include for-tablet-landscape-up {
            &::before,
            &::after {
                content: "";
                position: absolute;
                background: white;
            }

            &::before {
                bottom: 0;
                left: 0;
                width: 25%;
                height: 50%;
                clip-path: polygon(0 100%, 100% 100%, 0 0);
            }

            &::after {
                top: 0;
                right: 0;
                width: 37.5%;
                height: 75%;
                clip-path: polygon(0 0, 100% 100%, 100% 0);
            }
        }

        .featured-story {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column-reverse;

            &__image,
            &__body {
                @include for-tablet-portrait-up {
                    width: 50%;
                }
            }

            &__image {
                position: relative;
                background-color: $color-grey4;

                a {
                    display: block;
                    height: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    @include object-fit(cover);
                }
            }

            &__body {
                // background-color: blue;
                position: relative;
                display: flex;
                flex-direction: column;
                padding: $gutter-mobile * 2 $gutter-mobile;

                &::before {
                    @include for-tablet-portrait-up {
                        position: absolute;
                        top: 0;
                        // left: $gutter-mobile;
                        left: $gutter-tablet;
                    }

                    @include for-desktop-up {
                        left: 87px;
                    }
                }

                @include for-tablet-portrait-up {
                    padding: $gutter-tablet * 2 $gutter-tablet;
                }

                @include for-desktop-up {
                    padding: 75px 87px;
                }
            }

            // Typo

            &__pretitle {
                display: inline-block;
                color: $color-blue;
                font-size: 13px;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                line-height: 1.38;
                margin-bottom: 24px;
            }

            &__title {
                @include heading1;
                margin-bottom: 16px;

                @include for-tablet-portrait-up {
                    max-width: 316px;
                }
            }

            &__preface {
                @include text($color: $color-blue);

                @include for-tablet-portrait-up {
                    max-width: 270px;
                }
            }

            &__readmore {
                position: absolute;
                bottom: 0;
                font-size: 14px;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                display: inline-block;
                padding: 13px 23px;
                user-select: none;
                color: $color-white;
                background-color: $color-orange;
                outline: none;
            }
            // Modifiers

            &--top {
                padding-bottom: 30px;

                @include for-tablet-portrait-up {
                    padding-bottom: 70px;
                }

                @include for-tablet-landscape-up {
                    padding-bottom: 106px;
                }

                @include for-desktop-up {
                    padding-bottom: 160px;
                }

                @include for-tablet-portrait-up {
                    flex-direction: row;
                }

                .featured-story {
                    &__body {
                        justify-content: flex-end;
                        padding-bottom: 0;

                        @include double-arrow($direction: right);

                        &::before {
                            transform: translateY(-50%);
                        }

                        @include for-tablet-portrait-up {
                            padding-bottom: 24px;
                        }

                        @include for-desktop-up {
                            padding-bottom: 24px;
                        }
                    }

                    &__title {
                        @include for-tablet-portrait-up {
                            max-width: 260px;
                        }
                    }

                    &__readmore {
                        left: 0;
                    }
                }
            }

            &--bottom {
                padding-bottom: 0;

                @include for-tablet-portrait-up {
                    flex-direction: row-reverse;
                }

                @include for-tablet-landscape-up {
                    padding-bottom: 106px;
                }

                @include for-desktop-up {
                    padding-bottom: 160px;
                }

                .featured-story {
                    &__body {
                        justify-content: center;
                        @include double-arrow($direction: down);
                    }

                    &__readmore {
                        right: 0;
                    }

                    &__body {
                        @include for-desktop-up {
                            width: calc(50% - 96px);
                        }
                    }

                    &__image {
                        @include for-desktop-up {
                            width: calc(50% + 96px);
                        }
                    }
                }
            }
        }
    }
}
